import { React, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Container, Button, Accordion, Tab, Tabs } from 'react-bootstrap';
import Sections from './Sections';
import { ThreeDots } from 'react-loader-spinner';
import SectionModal from '../Modals/SectionModal';
import { ApiManager } from '../../ApiManager';
import { RecommendationForm } from '../Forms/RecommendationForm';

export const Admin = () => {

    const [sections, setSections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showSectionModal, setShowSectionModal] = useState(false);

    if (isLoading) {
        ApiManager.getInstance().getAllSections().then((result) => {
            result != null || undefined ? setSections(result) : setSections([]);
            setIsLoading(false);
        });
    }

    return (
        <>
            <AuthenticatedTemplate>
                <Container className="p-5">
                    {
                        isLoading ?
                            <div style={{ justifyContent: "center", display: "flex", margin: "0 auto" }}>
                                <ThreeDots color="#007396" />
                            </div>
                            :
                            <>
                                <h2>Universal Design Administration</h2>
                                <Tabs className="mt-3" defaultActiveKey="checklist">
                                    <Tab eventKey="checklist" title="Checklist">
                                        <SectionModal editingProject={false} setIsLoading={setIsLoading} showSectionModal={showSectionModal} setShowSectionModal={setShowSectionModal} createOrUpdate={1} />
                                        <div>
                                            <div className={"mt-3 mb-3 text-end"}>
                                                <Button onClick={() => setShowSectionModal(true)} variant="outline-success">Add Section</Button>
                                            </div>
                                            <Accordion defaultActiveKey="0">
                                                {sections.map((section) => <Sections editingProject={false} setIsLoading={setIsLoading} key={section.Id} section={section} canEditSections={true} />)}
                                            </Accordion>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="recommendations" title="Recommendations">
                                        <RecommendationForm />
                                    </Tab>
                                </Tabs>
                            </>
                    }
                </Container>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Container className="p-5">
                    <Navigate to="/" replace />;
                </Container>
            </UnauthenticatedTemplate>
        </>
    );
}