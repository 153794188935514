import { React, useState, useRef } from 'react';
import { Button, Tab, Tabs, Form, Accordion, Image, Col, Row } from 'react-bootstrap';
import ApiManager from '../../ApiManager';
import { ThreeDots } from 'react-loader-spinner';
import { Editor } from '@tinymce/tinymce-react';
import Sections from './Sections';

const ProjectTabs = (props) => {
    const project = props.project;
    const setIsLoading = props.setIsLoading;
    const executiveSummary = project.ExecutiveSummary;
    const sections = project.Sections;

    const [clientLogo, setClientLogo] = useState(project.ClientLogo);
    const [clientLogoFile, setClientLogoFile] = useState({});
    const [partneredWithConsultant, setPartneredWithConsultant] = useState(project.PartneredWithConsultant);

    const editorRef = useRef(null);
    const projectNameRef = useRef(project.Name);
    const clientNameRef = useRef(project.ClientName);
    const clientAddressRef = useRef(project.ClientAddress);
    const projectNumberRef = useRef(project.Number);

    const handleSelect = (key) => {
        if (key === 'evaluate')
            setIsLoading(true);
    };

    const fileUploaded = (e) => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            setClientLogo(URL.createObjectURL(imageFile))
            setClientLogoFile(imageFile);
        }
    }

    const onSubmit = () => {
        const formData = new FormData();
        formData.append('Id', project.Id);
        formData.append('ClientLogoFile', clientLogoFile);
        formData.append('Name', projectNameRef.current.value);
        formData.append('ClientName', clientNameRef.current.value);
        formData.append('ExecutiveSummary', editorRef.current.getContent());
        formData.append('ClientAddress', clientAddressRef.current.value);
        formData.append('Number', projectNumberRef.current.value);
        formData.append('PartneredWithConsultant', partneredWithConsultant);

        ApiManager.getInstance().updateProject(formData).then(() => {
            setIsLoading(true);
        });
    }

    return (
        <>
            <Tabs defaultActiveKey="evaluate" onSelect={(key) => handleSelect(key)}>
                <Tab eventKey="info" title="Project Admin" className="p-3">
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control as="textarea" autoFocus rows={1} defaultValue={project.Name} ref={projectNameRef} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Project Number</Form.Label>
                                <Form.Control as="textarea" autoFocus rows={1} defaultValue={project.Number} ref={projectNumberRef} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Client Name</Form.Label>
                                <Form.Control as="textarea" autoFocus rows={1} defaultValue={project.ClientName} ref={clientNameRef} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Client Address</Form.Label>
                                <Form.Control as="textarea" autoFocus rows={2} defaultValue={project.ClientAddress} ref={clientAddressRef} />
                            </Form.Group>
                        </Row>

                        <Form.Label>Client Logo</Form.Label>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Control type="file" onChange={(e) => fileUploaded(e)} />
                            </Form.Group>
                            <Form.Group as={Col} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                <Form.Check
                                    type="switch"
                                    label='Partnered with Consultant'
                                    id='partneredCheckbox'
                                    checked={partneredWithConsultant}
                                    onChange={(e) => setPartneredWithConsultant(e.target.checked)} />
                            </Form.Group>
                        </Row>
                        <Image src={clientLogo} style={{ width: "150px" }} className="mt-3" ></Image>


                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Executive Summary</Form.Label>
                                <Editor
                                    apiKey={process.env.REACT_APP_TINYAPI}
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={executiveSummary}
                                    init={{
                                        height: 550,
                                        menubar: true,
                                        plugins: 'lists',
                                        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
                                    }}
                                />
                            </Form.Group>
                        </Row>

                        <div className="text-end mb-3">
                            <Button variant="outline-success" onClick={() => onSubmit()}> Save </Button>
                        </div>
                    </Form>
                </Tab>
                <Tab eventKey="setup" title="Checklist">
                    <Accordion>
                        {sections.length > 0 ? sections.map((section) => <Sections setIsLoading={setIsLoading} editingProject={true} canEditSections={true} key={section.Id} section={section} />) :
                            <div style={{ justifyContent: "center", display: "flex", margin: "0 auto" }}>
                                <ThreeDots color="#007396" />
                            </div>
                        }
                    </Accordion>
                </Tab>
                <Tab eventKey="evaluate" title="Evaluate">
                    <Accordion>
                        {sections.length > 0 ? sections.map((section) => <Sections setIsLoading={setIsLoading} editingProject={true} canEditSections={false} key={section.Id} section={section} />) :
                            <div style={{ justifyContent: "center", display: "flex", margin: "0 auto" }}>
                                <ThreeDots color="#007396" />
                            </div>
                        }
                    </Accordion>
                </Tab>
            </Tabs>
        </>
    )
};

export default ProjectTabs;