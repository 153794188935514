import { React, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import ApiManager from '../../ApiManager';
import ProjectTabs from './ProjectTabs';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import ConfirmationModal from '../Modals/ConfirmationModal';

const ProjectDetails = () => {
    const params = useParams();
    const projectId = params.projectId;
    const [project, setProject] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const navigate = useNavigate();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [archiveProjectHandler, setArchiveProjectHandler] = useState(false);


    if (isLoading) {
        ApiManager.getInstance().getProjectById(projectId).then((response) => {
            setProject(response);
            setIsLoading(false);
        });
    }

    if (archiveProjectHandler) {
        ApiManager.getInstance().deleteProject(project).then(() => {
            setArchiveProjectHandler(false);
            navigate(`/projects/`, { state: { isLoading: true, saveProject: false } });
        });
    }

    const pdfExportClicked = () => {
        setIsLoadingPdf(true);
        ApiManager.getInstance().getProjectById(projectId).then((response) => {            
            ApiManager.getInstance().getPdfById(projectId).then((result) => {
                setProject(response);
                var blob = new Blob([result.data], { type: 'application/pdf' });
                const pdfURL = URL.createObjectURL(blob);
                setTimeout(() => {
                    window.open(pdfURL, '_blank');
                })
                setIsLoadingPdf(false);
            });
        });
    }

    return (
        <>
            <ConfirmationModal
                type="Project"
                setYesHandler={setArchiveProjectHandler}
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                Name={project.Name} />
            <Container>
                {isLoading || isLoadingPdf ?
                    <div style={{ justifyContent: "center", display: "flex", margin: "0 auto" }}>
                        <ThreeDots color="#007396" />
                    </div>
                    :
                    <>
                        <h1>{project.Name}</h1>
                        <div className="text-end mb-3">
                            {!isLoadingPdf &&
                                <>
                                    <Button style={{ marginRight: 15 }} size="sm" variant="outline-danger" onClick={() => setShowConfirmationModal(true)}>Archive Project</Button>
                                    <Button style={{ marginRight: 15 }} size="sm" variant="outline-warning" onClick={() => pdfExportClicked()}>Export PDF</Button>
                                </>
                            }
                        </div>
                        <ProjectTabs setIsLoading={setIsLoading} project={project} />
                    </>}
            </Container>
        </>
    );
}
export default ProjectDetails;