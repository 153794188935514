import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "dc846f24-0c85-4ee9-b827-77f8dfac83aa", // Client ID 
        authority: 'https://login.microsoftonline.com/d09971e3-d816-4a09-8b5e-8fdffc4d89cf', // Tenant ID of the React.JS App Registration
        redirectUri: process.env.REACT_APP_AUTHCONFIGREDIRECTURI,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    default:
                        throw new Error("Unknown logging level");
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
    scopes: ["api://4c1c165c-8566-4250-96cc-30e81cd04fbf/api.scope"],
};