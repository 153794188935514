import React from 'react'
import { NavigationBar } from './NavigationBar';
import { Outlet } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem'

export const PageLayout = (props) => {
    const date = new Date();
    const currentYear = date.getFullYear();
    return (
        <>
            <div style={{ minHeight: 'calc(100vh - 125px)' }}>
                <NavigationBar />
                <Outlet />
                {props.children}
            </div>
            <div style={{ height: '125px' }}>
                <hr className="mt-5" />
                <Navbar>
                    <Navbar.Toggle />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto ms-auto">
                            <NavItem>
                                &copy; {currentYear} - Progressive AE
                            </NavItem>
                        </Nav>
                        <Nav className="me-auto ms-auto">
                            <NavItem>
                                <Nav.Link href="https://progressiveae.sharepoint.com/sites/pr/sitepages/Universal-Design.aspx" target="_blank">Kate UD Resources</Nav.Link>
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </>
    );
};

