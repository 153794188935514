import { Button, OverlayTrigger, Tooltip, ListGroup } from "react-bootstrap";
import { React, useState } from 'react'
import { ApiManager } from "../../ApiManager"
import ConfirmationModal from '../Modals/ConfirmationModal';
import StrategyModal from "../Modals/StrategyModal";
import ProjectStrategyEvaluationModal from '../Modals/ProjectStrategyEvaluationModal';
import PencilIcon from '../../Assets/Images/pencil-square.svg'
import TrashIcon from '../../Assets/Images/trash.svg'


const Strategies = (props) => {
    const strategy = props.strategy;
    const chapter = props.chapter;
    const editingProject = props.editingProject
    const canEditSections = props.canEditSections
    const IsEditingSection = props.IsEditingSection
    const setReloadChapter = props.setReloadChapter;

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [enableStrategyHandler, setEnableStrategyHandler] = useState(false);
    const [showStrategyModal, setShowStrategyModal] = useState(false);
    const [showEvaluationModal, setShowEvaluationModal] = useState(false);

    const cursorStyle = editingProject && !canEditSections ? "pointer" : "auto";

    if (enableStrategyHandler && !editingProject) {
        strategy.Enabled = !strategy.Enabled;
        ApiManager.getInstance().deleteStrategy(strategy).then(() => {
            setReloadChapter(true);
            setEnableStrategyHandler(false);
        });
    }
    else if (enableStrategyHandler && editingProject) {
        strategy.Enabled = !strategy.Enabled;
        ApiManager.getInstance().deleteProjectStrategy(strategy).then(() => {
            setReloadChapter(true);
            setEnableStrategyHandler(false);
        });
    }

    const strategyClicked = () => {
        if (editingProject && !canEditSections)
            setShowEvaluationModal(true);
    }

    return (
        <>
            <ProjectStrategyEvaluationModal
                chapter={chapter}
                strategy={strategy}
                showEvaluationModal={showEvaluationModal}
                setShowEvaluationModal={setShowEvaluationModal}
                setReloadChapter={setReloadChapter}
            />
            <StrategyModal
                editingProject={editingProject}
                strategy={strategy}
                createOrUpdate={2}
                chapter={chapter}
                showStrategyModal={showStrategyModal}
                setShowStrategyModal={setShowStrategyModal}
                setReloadChapter={setReloadChapter} />
            <ConfirmationModal
                type="strategy"
                setYesHandler={setEnableStrategyHandler}
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                Name={strategy.Description} />
            {
                strategy.Enabled &&
                <ListGroup.Item onClick={() => strategyClicked()} style={{ backgroundColor: strategy.LastModified === null ? "#FFE55E" : ""}}>
                    <div key={strategy.Id} style={{ display: "flex", margin: "5px" }}>
                        <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "85%", cursor: cursorStyle }} >{strategy.Description}</div>
                        {IsEditingSection &&
                            <div style={{ marginLeft: "auto" }}>
                                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-sections"}>Edit Strategy</Tooltip>}>
                                    <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => setShowStrategyModal(true)} ><img src={PencilIcon} alt="Pencil Icon" /></Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-sections"}>Remove Strategy</Tooltip>}>
                                    <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => setShowConfirmationModal(true)}><img src={TrashIcon} alt="Trash Icon" /></Button>
                                </OverlayTrigger>
                            </div>
                        }
                    </div>
                </ListGroup.Item>
            }
        </>
    )
};

export default Strategies;