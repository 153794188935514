import { React, useEffect, useState, useRef, useCallback } from 'react'
import { Button, Modal, Form, OverlayTrigger, Tooltip, Image, Figure, Dropdown } from "react-bootstrap";
import { ApiManager } from "../../ApiManager"
import { ThreeDots } from 'react-loader-spinner'

const ProjectStrategyEvaluationModal = (props) => {
    const chapter = props.chapter;
    const strategy = props.strategy;
    const setReloadChapter = props.setReloadChapter;
    const setShowEvaluationModal = props.setShowEvaluationModal;
    const showEvaluationModal = props.showEvaluationModal;

    const [strat, setStrat] = useState({});
    const [strategyObservations, setStrategyObservations] = useState(strat !== undefined ? strat.Observations : "");
    const [strategyRecommendations, setStrategyRecommendations] = useState(strat !== undefined ? strat.Recommendations : "");
    const [strategyPriority, setStrategyPriority] = useState(strat !== undefined ? strat.Priority : "");
    const [strategyComplies, setStrategyComplies] = useState(null);
    const [badgeBg, setBadgeBg] = useState("success");
    const [badgeTooltip, setBadgeTooltip] = useState("default");
    const [prevDisabled, setPrevDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [strategyPhotosToUpload, setStrategyPhotosToUpload] = useState({});
    const [strategyPhotos, setStrategyPhotos] = useState([]);
    const [photoClicked, setPhotoClicked] = useState([]);
    const [photoRotated, setPhotoRotated] = useState(false);
    const fileInputRef = useRef(null);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [allRecommendations, setAllRecommendations] = useState([]);
    const [showRecommendationTextBox, setShowRecommendationTextBox] = useState(true);
    const [recommendationToggleHeader, setRecommendationToggleHeader] = useState("Saved Recommendations");
    const [isLoadingStrategyPhotoImages, setIsLoadingStrategyPhotoImages] = useState(true);

    const currentStrategyData = () => {
        var strategyToSave = {
            Description: strat.Description,
            Observations: strategyObservations !== undefined ? strategyObservations : strat.Observations,
            Recommendations: strategyRecommendations !== undefined ? strategyRecommendations : strat.Recommendations,
            Priority: strategyPriority !== undefined ? Number(strategyPriority) : strat.Priority,
            Enabled: true,
            Complies: strategyComplies !== undefined ? strategyComplies : strat.Complies,
            ChapterId: chapter.Id,
            Id: strat.Id,
        };
        return strategyToSave;
    }

    const handlePrevious = (shouldSetStrategy) => {
        saveStrategy();
        var enabledStrategies = chapter.Strategies.filter((strat) => strat.Enabled === true);
        let index = enabledStrategies.map((strategy) => strategy.Id).indexOf(strat.Id);
        let indexPrev = index - 1;
        let indexBeforePrev = index - 2;

        if (indexPrev > -1) {
            if (shouldSetStrategy)
                setStrat(enabledStrategies[indexPrev]);
            setNextDisabled(false);
        }
        if (indexBeforePrev < 0)
            setPrevDisabled(true);
    };

    const handleNext = (shouldSetStrategy) => {
        saveStrategy();
        var enabledStrategies = chapter.Strategies.filter((strat) => strat.Enabled === true);
        let index = enabledStrategies.map((strategy) => strategy.Id).indexOf(strat.Id);
        let indexNext = index + 1;
        let indexAfterNext = index + 2;

        if (indexNext < enabledStrategies.length) {
            if (shouldSetStrategy)
                setStrat(enabledStrategies[indexNext]);
            setPrevDisabled(false);
        }
        if (indexAfterNext > enabledStrategies.length - 1)
            setNextDisabled(true);
    };

    const setInitalPreviousNextStates = useCallback(() => {
        var enabledStrategies = chapter.Strategies.filter((strat) => strat.Enabled === true);
        let index = enabledStrategies.map((strategy) => strategy.Id).indexOf(strat.Id);
        let indexPrev = index - 1;
        let indexNext = index + 1;

        indexPrev < 0 ? setPrevDisabled(true) : setPrevDisabled(false);
        indexNext > enabledStrategies.length - 1 ? setNextDisabled(true) : setNextDisabled(false);
    }, [chapter.Strategies, strat.Id]);

    const getStrategyPhotosAndSetState = useCallback((strat) => {
        ApiManager.getInstance().getProjectStrategyPhotosByStrategyId(strat.Id).then((response) => {
            setStrategyPhotos(response);
            setIsLoadingStrategyPhotoImages(false);
        });
    }, []);

    const getAllRecommendations = () => {
        ApiManager.getInstance().getAllRecommendations().then((result) => {
            setAllRecommendations(result.data);
        });
    }

    if (strategyPhotosToUpload.length > 0) {
        const formData = new FormData();
        formData.append('StrategyId', strat.Id);

        var currentStrategy = currentStrategyData();
        for (let i = 0; i < strategyPhotosToUpload.length; i++)
            formData.append('Photos', strategyPhotosToUpload[i]);

        ApiManager.getInstance().createProjectStrategyPhotos(formData).then(() => {
            fileInputRef.current.value = "";
            setStrategyPhotosToUpload({});
            getStrategyPhotosAndSetState(currentStrategy);
        });
    }

    if (showEvaluationModal && Object.keys(strat).length === 0) {
        let s = chapter.Strategies.find(s => s.Id === strategy.Id);
        if (s !== null || s !== undefined)
            setStrat(s);
    }
    else if (!showEvaluationModal && Object.keys(strat).length > 0) {
        setStrat({});
    }

    useEffect(() => {
        if (strat !== undefined && Object.keys(strat).length !== 0) {
            setIsLoadingStrategyPhotoImages(true);
            setStrategyPhotos([]);
            getAllRecommendations();
            setStrategyPriority(strat.Priority);
            setBadgeBgSwitch(strat.Priority);
            setStrategyObservations(strat.Observations);
            setStrategyRecommendations(strat.Recommendations);
            setStrategyComplies(strat.Complies);
            setShowDeletePrompt(false);
            setInitalPreviousNextStates();            
            if (strat.Id !== undefined)
                getStrategyPhotosAndSetState(strat);
            else
                setIsLoadingStrategyPhotoImages(false);
                       
        }
    }, [getStrategyPhotosAndSetState, strat, setInitalPreviousNextStates]);

    useEffect(() => {
        if (allRecommendations.length > 0) {
            var enabledRecommendations = allRecommendations.filter((rec) => rec.Enabled === true);
            let index = enabledRecommendations.map((rec) => rec.Value).indexOf(strat.Recommendations);

            if (index > -1) {
                setRecommendationToggleHeader(enabledRecommendations[index].Value);
                setShowRecommendationTextBox(false);
            }
            else {
                setRecommendationToggleHeader("Custom");
                setShowRecommendationTextBox(true);
            }
        }
    }, [allRecommendations]) // eslint-disable-line react-hooks/exhaustive-deps

    const deleteImage = () => {
        ApiManager.getInstance().deleteProjectStrategyPhoto(photoClicked.Id).then(() => {
            setShowDeletePrompt(false);
            var currentStrategy = currentStrategyData();
            getStrategyPhotosAndSetState(currentStrategy);
        });
    };

    const onObservationsChanged = (val) => {
        setStrategyObservations(val);
    };

    const onCompliesChanged = (val) => {
        switch (val) {
            case "0":
                setStrategyComplies(null);
                break;
            case "1":
                setStrategyComplies(true);
                break;
            case "2":
                setStrategyComplies(false);                
                break
            default:
                setStrategyComplies(null);
        }
    };

    const onPriorityChanged = (val) => {
        setStrategyPriority(val);
        setBadgeBgSwitch(Number(val));
    };

    // when user types their own
    const onRecommendationsChanged = (val) => {
        setStrategyRecommendations(val);
    };

    // when user chooses one from the dropdown
    const cannedRecommendationClicked = (rec) => {
        setStrategyRecommendations(rec.Value);
        setRecommendationToggleHeader(rec.Value);
        setShowRecommendationTextBox(false);
    }

    // When user chooses the 'custom' choice from the dropdown
    const customRecommendationClicked = () => {
        setShowRecommendationTextBox(true);
        setRecommendationToggleHeader("Custom");
    }

    const setBadgeBgSwitch = (priority) => {
        switch (priority) {
            default:
                break;
            case 1:
                setBadgeBg("#FFE55E");
                setBadgeTooltip("A UD strategy having a relatively low cost (or no cost because of alignment with code requirements) with high impact to the organization and users");
                break;
            case 2:
                setBadgeBg("#58a7bf");
                setBadgeTooltip("A UD strategy having a higher cost with relative impact to the organization and users.");
                break;
            case 3:
                setBadgeBg("#A1D884");
                setBadgeTooltip("Driven by a specific owner requirement or unique opportunity");
                break;
        }
    }

    const onHideHandler = () => {
        setShowEvaluationModal(false);
        saveStrategy();
    };

    const saveStrategy = () => {
        if ((strat.Observations !== strategyObservations || strat.Recommendations !== strategyRecommendations || strat.Priority !== strategyPriority || strat.Complies !== strategyComplies) && strategyPriority !== '0' && strategyPriority !== undefined) {
            var strategyToSave = currentStrategyData();
            ApiManager.getInstance().updateProjectStrategy(strategyToSave).then(() => {
                setReloadChapter(true);
            });
        }
    };

    const ImageClicked = (photo) => {
        setShowDeletePrompt(true);
        setPhotoClicked(photo);
        setPhotoRotated(photo.Rotated);
    };

    const rotatedCheckboxOnChange = (checked) => {
        setPhotoRotated(checked);
        var strategyPhotoToSave = {
            Rotated: checked,
            Id: photoClicked.Id
        }
        ApiManager.getInstance().updateProjectStrategyPhoto(strategyPhotoToSave);
    };

    return (
        <>
            <Modal show={showEvaluationModal} onHide={() => onHideHandler()} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {strat !== undefined ? strat.Description : ""}&nbsp;
                        {strategyPriority > 0 ?
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id={"tooltip-strategy-badge"}>{badgeTooltip}</Tooltip>}>
                                <span className="badge" style={{ backgroundColor: badgeBg, color: 'black' }}>Priority {strategyPriority !== null ? strategyPriority : ""}</span>
                            </OverlayTrigger> :
                            <></>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoadingStrategyPhotoImages ?
                        <div style={{ justifyContent: "center", display: "flex", margin: "0 auto" }}>
                            <ThreeDots color="#007396" />
                        </div> :                    
                        <Form className="row g-3">
                            <Form.Group>
                                <Form.Label>Observations</Form.Label>
                                <Form.Control
                                    onChange={(e) => onObservationsChanged(e.target.value)} as="textarea" autoFocus rows={2}
                                    value={strategyObservations !== null ? strategyObservations : ""} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Complies</Form.Label>
                                <Form.Select
                                    label="Complies"
                                    onChange={(e) => onCompliesChanged(e.target.value)}
                                    value={strategyComplies !== null ? strategyComplies === false ? "2" : "1" : "0"}>
                                    <option value="0">Not Applicable</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Priority</Form.Label>
                                <Form.Select
                                    label="priority"
                                    onChange={(e) => onPriorityChanged(e.target.value)}
                                    value={strategyPriority !== null ? strategyPriority : ""}
                                    style={{ cursor: 'auto' }}>
                                    <option value="0">Please select a Priority</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Recommendations</Form.Label>
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-secondary" id="dropdown-Recommendations" className="mb-3">{recommendationToggleHeader}</Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item
                                            onClick={() => customRecommendationClicked()}
                                            eventKey='customRecommendationEventKey'>Custom
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        {allRecommendations.map((rec) => rec.Enabled &&
                                            <Dropdown.Item
                                                key={rec.Value}
                                                onClick={() => cannedRecommendationClicked(rec)}
                                                eventKey={rec.Id}>{rec.Value}
                                            </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                    style={{ cursor: !showRecommendationTextBox ? 'not-allowed' : 'auto' }}
                                    disabled={!showRecommendationTextBox}
                                    onChange={(e) => onRecommendationsChanged(e.target.value)}
                                    as="textarea"
                                    autoFocus
                                    rows={2}
                                    value={strategyRecommendations !== null ? strategyRecommendations : ""} />
                                <Figure.Caption style={{ display: !showRecommendationTextBox ? 'inline' : 'none', color: '#006F62' }}>Select "Custom" in the Recommendations dropdown to type your own</Figure.Caption>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Upload Photo</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => setStrategyPhotosToUpload(e.target.files)}
                                    ref={fileInputRef}
                                    accept=".jpg,.png"
                                    multiple />
                                {strategyPhotos.map((photo) =>
                                    <Image
                                        onClick={() => ImageClicked(photo)}
                                        key={photo.Path}
                                        src={photo.ByteData !== null ? `data:image/png;base64,${photo.ByteData}` : photo.Path}
                                        style={{ width: "120px", height: "auto", cursor: "pointer", margin: "10px" }}
                                        className="mt-3"></Image>
                                )}
                                <div style={{ display: showDeletePrompt === true ? "flex" : "none" }}>
                                    <Form.Label style={{ color: "red", margin: "10px" }}>Delete {photoClicked.Path !== undefined ? photoClicked.Path.split("/").pop() : "Photo"}? </Form.Label>
                                    <div className="mt-10">
                                        <Button style={{ margin: "10px" }} variant="outline-secondary" onClick={() => deleteImage()}>Yes</Button>
                                        <Button style={{ margin: "10px" }} variant="outline-secondary" onClick={() => setShowDeletePrompt(false)}>No</Button>
                                    </div>
                                </div>
                                <div style={{ display: showDeletePrompt === true ? "flex" : "none" }}>
                                    <div style={{ marginLeft: "10px" }}>
                                        <Form.Check type="checkbox" label="Remove Rotation" checked={photoRotated} onChange={(e) => rotatedCheckboxOnChange(e.target.checked)} />
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => handlePrevious(true)} disabled={prevDisabled}>Previous</Button>
                    <Button variant="outline-secondary" onClick={() => handleNext(true)} disabled={nextDisabled}>Next</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProjectStrategyEvaluationModal;